import { useEffect, useState } from "react";
import { BsFillPersonFill } from 'react-icons/bs';
import { MdLock } from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import "../styles/login.css";
import axios from "axios";
import { useNavigate, redirect } from "react-router-dom";

const Login = () => {
  // const [nextStep, setNextStep] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState([]);
  const [notFound, setNotFound] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  // Login
  const logIn = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const data = {
      email: email,
      password: password
    };
    //n1a2o3m4

    console.log(data);

    // Save form data
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/web/login`, data).then((res) => {
        if (res.data.status === 200) {
          setButtonLoading(false);
          setNotFound('');
          localStorage.setItem('user_id', res.data.user_id);
          localStorage.setItem('user_token', res.data.user_token);
          navigate("/view-users");
        }
        else if (res.data.status === 401) {
          setNotFound(res.data.message);
          setButtonLoading(false);
        }
        else {
          setNotFound(res.data.message);
          setButtonLoading(false);
        }
      });
    });
  };

  return (
    <div className="flex flex-col items-center justify-between h-screen">
      <form
        onSubmit={logIn}
        className="w-96 my-auto"
      >
        <div className="flex justify-center">
          <img src="/app-icon.png" className="logo" alt="cba-logo" />
        </div>
        {
          (() => {
            return (
              <div>
                <h1 className="text-3xl font-black font-poppins text-center mt-10 mb-5">
                  Bienvenue
                </h1>

                <p className="text-center text-muted-2 mt-3 mb-5 font-poppins">Connectez-vous pour accédez à votre compte</p>

                <label className="relative block">
                  {/* <span className="sr-only">Matricule</span> */}
                  <span className="absolute inset-y-0 left-0 flex items-center text-indigo-400 pl-4">
                    <BsFillPersonFill fontSize={18} />
                  </span>
                  <input className="w-full shadow-sm focus:outline-none focus:border-indigo-400 text-muted pl-10 pr-3" placeholder="Email" type="text" name="email" onChange={(e) => setEmail(e.currentTarget.value)} />
                </label>

                <label className="relative block">
                  {/* <span className="sr-only">Search</span> */}
                  <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <MdLock color="green" fontSize={18} />
                  </span>
                  <input className="w-full shadow-sm focus:outline-none focus:border-green-600 text-muted pl-10 pr-3" placeholder="Mot de passe" type="password" name="password" onChange={(e) => setPassword(e.currentTarget.value)} />
                </label>
                <button className="flex justify-center align-content-center  w-full bg-indigo-400 hover:bg-indigo-500" type="submit">
                  {buttonLoading ? <span className="animate-spin"><CgSpinner fontSize={22} /></span> : 'Connexion'}
                </button>
              </div>
            );
          })()
        }
        <p className="text-center text-red-600">{notFound}</p>
      </form>
    </div>
  );
}

export default Login