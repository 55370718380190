import React, { useEffect } from "react";

import NFC from "../assets/ALTECHS/NFC.png";
import logoAltechsR from "../assets/ALTECHS/logoAltechsR.png";
import logoAdna from "../assets/ALTECHS/logoAdna.png";
import IconR from "../assets/ALTECHS/IconR.png";
import Rectangle from "../assets/ALTECHS/Rectangle.png";
import RectangleVert from "../assets/ALTECHS/RectangleVert.png";
import ligneR from "../assets/ALTECHS/ligneR.png";
import "../styles/ALTECHSVcard.css";
import LigneB from "../assets/ALTECHS/LigneB.png";
import ligneVertB from "../assets/ALTECHS/ligneVertB.png";
import iconWorld from "../assets/ALTECHS/iconWorld.png";
import { useParams } from "react-router-dom";
import QRCodeStyling from "qr-code-styling";

function ALTECHSCard({ user, title1, title2 }) {
  const dataR = localStorage.getItem("slug");
  const { id } = useParams();

  useEffect(() => {
    qrCodeR.append(document.getElementById("qrcodeR"));
  }, [id]);

  const qrCodeR = new QRCodeStyling({
    width: 190,
    height: 190,
    margin: 2,
    type: "svg",
    data: `https://vcard.adna.cards/vcard/card/qr/${dataR}`,
    dotsOptions: {
      color: "black",
      type: "dots",
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      type: "dots",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  return (
    <>
      <div id="cardR" className="backImgAL">
        <div className="flex flex-row">
          <div className="smartCardAL">
            <p
              className="text-black font-extrabold font-harabara"
              style={{
                fontSize: "29px",
                lineHeight: "22px",
                letterSpacing: "1px",
              }}
            >
              Smart
              <br />
              <span style={{ color: "#08a84e", letterSpacing: "1.5px" }}>
                Business
              </span>
              <br />
              Card
            </p>
          </div>
          <div className="smartCardAL ml-3 mt-0.5">
            <img src={NFC} alt="" style={{ width: "49.8px" }} />
          </div>
        </div>
        <div className="logoCardAL">
          <img
            src={logoAltechsR}
            alt=""
            style={{ width: "251px", height: "135px" }}
          />
        </div>
        <div className="iconCardAL">
          <img
            src={IconR}
            alt=""
            style={{ width: "41.5px", height: "41.5px" }}
          />
        </div>
        <div className="flex flex-col nameSectionAL">
          {user.firstname &&
          user.lastname &&
          user.lastname.length + user.firstname.length < 36 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "25.5px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "23px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          )}
          {user.title && user.title.length < 58 ? (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "18.2px",
                lineHeight: "18px",
                color: "#08a84e",
              }}
            >
              {user.title}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "16.2px",
                lineHeight: "18px",
                color: "#08a84e",
              }}
            >
              {user.title}
            </p>
          )}
        </div>
        <div className={"gradient"}>
          <img src={Rectangle} alt="" style={{ width: "391px" }} />
        </div>
        <div className={"vert"}>
          <img src={RectangleVert} alt="" style={{ width: "216px" }} />
        </div>
        <div className={"ligne1"}>
          <img src={ligneR} alt="" style={{ width: "6px", height: "38px" }} />
        </div>
        <div className="logoAdna">
          <img
            src={logoAdna}
            alt=""
            style={{ width: "65px", height: "25px" }}
          />
        </div>
        <div className={"ligne2"}>
          <img src={ligneR} alt="" style={{ width: "6px", height: "32px" }} />
        </div>
      </div>
      <br />
      <div id="cardV" className="backImgVersoAL">
        <div style={{ transform: "translate(1px, 0px)" }}>
          <img src={LigneB} alt="" style={{ width: "380px" }} />
        </div>
        <div className="flex">
          <div
            className="flex justify-center items-center bg-white"
            style={{
              transform: "translate(37px, -420px) rotate(-56deg) ",
              padding: "10px",
            }}
          >
            <div
              id="qrcodeR"
              className="flex justify-center"
              style={{
                width: 190,
                height: 190,
                backgroundColor: "rgb(255 255 255)",
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            // transform: "translate(378px, -429px)"
            // transform: "translate(378px, -410px)",
            transform: "translate(378px, -433px)",
          }}
        >
          <img src={ligneVertB} alt="" style={{ width: "273px" }} />
        </div>
        <div
          style={{
            // transform: "translate(274px, -518.5px)"
            transform: "translate(274px, -540px)",
          }}
        >
          <img src={iconWorld} alt="" style={{ width: "30px" }} />
        </div>
        <div className="altechs">
          <p
            className="text-black font-bold italic font-josefinSans"
            style={{
              fontSize: "23.6px",
              lineHeight: "18px",
            }}
          >
            altechs.africa
          </p>
        </div>
      </div>
    </>
  );
}

export default ALTECHSCard;
