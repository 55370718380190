import React, { useEffect } from "react";
import SBCard from "../assets/PNUD/SBCard.png";
import Logo from "../assets/PNUD/Logo.png";
import Icon from "../assets/PNUD/Icon.png";
import Color from "../assets/PNUD/Color.png";
import QRCodeStyling from "qr-code-styling";
import ColorV2 from "../assets/PNUD/ColorV2.png";
import { useParams } from "react-router-dom";
import "../styles/PNUDVcard.css";

function PNUDCard({ user, title1, title2 }) {
  const dataR = localStorage.getItem("slug");
  const dataV = localStorage.getItem("vcard");
  const { id } = useParams();

  useEffect(() => {
    qrCodeR.append(document.getElementById("qrcodeR"));
    qrCodeV.append(document.getElementById("qrcodeV"));
  }, [id]);

  const qrCodeR = new QRCodeStyling({
    width: 190,
    height: 190,
    margin: 2,
    type: "svg",
    data: `https://vcard.adna.cards/vcard/card/qr/${dataR}`,
    dotsOptions: {
      color: "black",
      type: "dots",
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      type: "dots",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  const qrCodeV = new QRCodeStyling(
    dataV && dataV.length < 360
      ? {
          width: 180,
          height: 180,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 500
      ? {
          width: 210,
          height: 210,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 661
      ? {
          width: 230,
          height: 230,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 780
      ? {
          width: 240,
          height: 240,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : {
          width: 260,
          height: 260,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
  );

  return (
    <>
      {/* Front  */}
      <div id="cardR" className="backImg">
        <div className="logoCard">
          <img src={Logo} alt="" style={{ width: "100px", height: "227px" }} />
        </div>
        <div className="flex flex-row">
          <div className="smartCard">
            <img src={SBCard} alt="" style={{ width: "33px" }} />
          </div>
          <div className="smartCard ml-3">
            <p
              className="text-black font-bold font-poppins"
              style={{
                fontSize: "15px",
                lineHeight: "13px",
              }}
            >
              Smart
              <br />
              <span style={{ color: "#0568b0" }}>Business</span>
              <br />
              Card
            </p>
          </div>
        </div>
        <div className="compName">
          <p
            className="text-black font-extrabold font-poppins"
            style={{
              fontSize: "19.5px",
              lineHeight: "19px",
            }}
          >
            United Nations
            <br /> Development Programme
          </p>
        </div>
        <div
          id="qrcodeR"
          className="flex justify-center qrcode py-1"
          style={{
            width: 190,
            height: 190,
            backgroundColor: "rgb(255 255 255)",
          }}
        ></div>
        <div className="iconCard">
          <img src={Icon} alt="" style={{ width: "106px", height: "131px" }} />
        </div>
        <div className="flex flex-col nameSection">
          {user.firstname &&
          user.lastname &&
          user.lastname.length + user.firstname.length < 15 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "26px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 20 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{ fontSize: "24px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 25 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{ fontSize: "20px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 31 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{ fontSize: "17px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 35 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{ fontSize: "16px", lineHeight: "29px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : (
            <>
              <p
                className="text-black font-poppins font-extrabold "
                style={{ fontSize: "17.5px", lineHeight: "10px" }}
              >
                {user.firstname}
              </p>
              <p
                className="text-black font-poppins font-extrabold "
                style={{ fontSize: "17.5px", lineHeight: "29px" }}
              >
                {user.lastname}
              </p>
            </>
          )}
          {user.title && user.title.length < 20 ? (
            <p
              className="text-black font-poppins font-bold"
              style={{
                fontSize: "18.1px",
                lineHeight: "16px",
                color: "#4f4f4f",
              }}
            >
              {user.title}
            </p>
          ) : user.title && user.title.length < 25 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{
                fontSize: "16.1px",
                lineHeight: "16px",
                color: "#4f4f4f",
              }}
            >
              {user.title}
            </p>
          ) : user.title && user.title.length < 30 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{
                fontSize: "14.1px",
                lineHeight: "16px",
                color: "#4f4f4f",
              }}
            >
              {user.title}
            </p>
          ) : user.title && user.title.length < 40 ? (
            <p
              className="text-black font-poppins font-extrabold "
              style={{
                fontSize: "13.1px",
                lineHeight: "16px",
                color: "#4f4f4f",
              }}
            >
              {user.title}
            </p>
          ) : (
            <>
              <p
                className="text-black font-poppins font-extrabold "
                style={{
                  fontSize: "12.1px",
                  // lineHeight: "16px",
                  color: "#4f4f4f",
                }}
              >
                {title1}
              </p>
              <p
                className="text-black font-poppins font-extrabold "
                style={{
                  fontSize: "12.1px",
                  lineHeight: "16px",
                  color: "#4f4f4f",
                }}
              >
                {title2}
              </p>
            </>
          )}
        </div>
        <div
          className={
            user.title && user.title.length < 39 ? "colorCard" : "colorCard2"
          }
        >
          <img src={Color} alt="" style={{ width: "518px" }} />
        </div>
      </div>
<br />
      {/* Back  */}
      <div
        id="cardV"
        className="flex flex-row justify-center items-center backImgVerso gap-10"
      >
        <div className="colorV mt-5">
          <img
            src={ColorV2}
            alt=""
            style={{ width: "206px", transform: "translate(8px, -2px)" }}
          />
        </div>
        <div className="">
          <p
            className="text-black font-extrabold font-poppins pr-1"
            style={{
              fontSize: "19px",
              lineHeight: "21px",
              color: "#0568b0",
              transform: "translate(19px, 11px)",
            }}
          >
            SUSTAINABLE
            <br />
            DEVELOPMENT
            <br />
            GOALS
          </p>
        </div>
        <div
          id="qrcodeV"
          className="flex justify-center items-center mt-2 ml-3"
          style={
            dataV && dataV.length < 360
              ? {
                  width: 180,
                  height: 180,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(1)",
                }
              : dataV && dataV.length < 500
              ? {
                  width: 210,
                  height: 210,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.9)",
                }
              : dataV && dataV.length < 661
              ? {
                  width: 230,
                  height: 230,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.9)",
                }
              : dataV && dataV.length < 780
              ? {
                  width: 240,
                  height: 240,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.8)",
                }
              : {
                  width: 260,
                  height: 260,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.7)",
                }
          }
        ></div>
      </div>
    </>
  );
}

export default PNUDCard;
