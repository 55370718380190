import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { FaUser } from "react-icons/fa6";
import { FaEye, FaEdit } from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import EditUser from "./EditUser";
import { useTranslation } from "react-i18next";

const ViewUsers = () => {
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const [userList, setUserList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const user_token = localStorage.getItem("user_token");

  useEffect(() => {
    if (!user_token) {
      history("/");
    }

    setLoading(true);
    const data = {
      search: search,
      limit: 50,
    };
    // console.log(data);

    axios.post(`api/get-users`, data).then((res) => {
      if (res.data.status === 200) {
        setUserList(res.data.users);
        setLoading(false);
      }
    });
  }, [user_token, search]);

  const navig_ate = (id, vcard, slug) => {
    localStorage.setItem("vcard", vcard);
    localStorage.setItem("slug", slug);
    history(`/view-user/${id}`);
  };

  return (
    <div className="container mx-auto px-8 font-poppins mt-8">

      
      <div className="flex-row flex  ">
        <form class="max-w-sm w-full mx-auto">
          <label for="simple-search" class="sr-only">
            Search
          </label>
          <div class="relative w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
              placeholder={t("description.searchbar")}
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </form>

        <div className="mr-5 mb-8 mt-1">
          <Link
            to={`/add-user`}
            className="text-sm cursor-pointer  text-white block border-0 py-2.5 px-8  rounded bg-indigo-400 border hover:bg-indigo-500 hover:text-white"
          >
            Add User
          </Link>
        </div>
      </div>

      {/* Table begin  */}
      <div>
        {loading ? (
          <div className="h-screen flex items-center justify-center">
            <CgSpinner
              className="animate-spin h-10 w-40 text-slate-400 mx-auto "
              fontSize={22}
            />
          </div>
        ) : (
          <TableContainer component={Paper} className="mt-8">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-poppins font-bold">#</span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold"></span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabFisrtname")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabLastname")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">E-mail</span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabPhone")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">
                      {t("description.tabCompany")}
                    </span>
                  </TableCell>
                  <TableCell align="">
                    <span className="font-poppins font-bold">Actions</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList &&
                  userList.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <span className="font-poppins">{index + 1}</span>
                      </TableCell>
                      <TableCell align="">
                        <div className="flex justify-center items-center w-12">
                          {user.image !== null ? (
                            <img
                              src={`https://api.adna.cards/public/storage/user_images/${user.profile_photo_path}`}
                              alt=""
                              className="shadow rounded-full w-12 h-12 align-middle border-none"
                            />
                          ) : (
                            <span className="inline-block p-4 rounded-full bg-gray-100">
                              <FaUser color="#727565" size={20} />
                            </span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{user.firstname}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{user.lastname}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">{user.email}</span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">
                          {user.phone}
                          {user.phone2 !== null ? "/" + user.phone2 : ""}
                        </span>
                      </TableCell>
                      <TableCell align="">
                        <span className="font-poppins">
                          {user.company && user.company.name}
                        </span>
                      </TableCell>
                      <TableCell align="">
                        <span className=" flex flex-row gap-2">
                          <Button
                            onClick={() =>
                              navig_ate(user.id, user.vcard, user.slug)
                            }
                            variant="contained"
                            // href="#contained-buttons"
                            size="small"
                            className="font-poppins"
                            style={{
                              textTransform: "lowercase",
                              backgroundColor: "rgb(129 140 248)",
                            }}
                          >
                            <FaEye />
                            <span className="font-poppins">
                              {t("description.view")}
                            </span>
                          </Button>
                          <Button
                            // onClick={() => handleOpen(user.id)}
                            variant="contained"
                            // href="#contained-buttons"
                            size="small"
                            className="font-poppins bg-white"
                            style={{
                              textTransform: "lowercase",
                              backgroundColor: "white",
                            }}
                          >
                            <Link to={`/edit-user/${user.id}`} className="pl-1">
                              <div className="flex flex-row items-center">
                                <FaEdit color="rgba(0, 0, 0, 0.87)" />
                                <span
                                  className="pl-1 font-poppins"
                                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                                >
                                  {t("description.edit")}
                                </span>
                              </div>
                            </Link>
                          </Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {/* End Table  */}
    </div>
  );
};

export default ViewUsers;
