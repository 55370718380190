import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import { MdOutlinePhoneAndroid, MdEmail, MdArrowBackIos } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import { FaUser } from "react-icons/fa6";

import QRCodeStyling from "qr-code-styling";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import jsPDF from "jspdf";
import PNUDCard from "../Components/PNUDCard";
import ALTECHSCard from "../Components/ALTECHSCard";
import STANDARDCard from "../Components/STANDARDCard";

const ViewUser = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  // console.log(i18n.resolvedLanguage);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  var presDate;
  var expi = 2;

  function formatDate(date = new Date(), expi) {
    var day, month, year;
    if (expi === 2) {
      year = Math.floor(parseFloat(date.getFullYear())) + expi;
      month = date.getMonth() + 1;
      day = date.getDate();
    } else {
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return day + "/" + month + "/" + year;
  }
  const user_token = localStorage.getItem("user_token");

  useEffect(() => {
    if (!user_token) {
      navigate("/");
    }

    axios.get(`api/get-user/${id}`).then((res) => {
      if (res.data.status === 200) {
        setUser(res.data.user);
      }
    });
  }, [id]);

  presDate = new Date(user.prestationDate);

  const generatePDF = async (fileName) => {
    const doc = new jsPDF("landscape", "px", [720, 450]);

    const frontCanvas = await html2canvas(document.getElementById("cardR"), {
      scale: 2,
    });
    const frontImgData = frontCanvas.toDataURL("image/png");
    doc.addImage(frontImgData, "PNG", 0, 0, 720, 450);

    doc.addPage();

    const backCanvas = await html2canvas(document.getElementById("cardV"), {
      scale: 2,
    });
    const backImgData = backCanvas.toDataURL("image/png");
    doc.addImage(backImgData, "PNG", 0, 0, 720, 450);

    doc.save(`${fileName}.pdf`);
  };

  console.log(user.title && user.title.length);
  // console.log(user.lastname && user.lastname.length + user.firstname.length);
  let title1;
  let title2;

  if (user.title && user.title.length > 39) {
    title1 =
      user.title.split(" ")[0] +
      " " +
      user.title.split(" ")[1] +
      " " +
      user.title.split(" ")[2] +
      " " +
      user.title.split(" ")[3];
    // console.log(user.title.split(" ")[0]);

    title2 =
      user.title.split(" ")[4] +
      " " +
      (user.title.split(" ")[5] !== undefined
        ? user.title.split(" ")[5]
        : " ") +
      " " +
      (user.title.split(" ")[6] !== undefined
        ? user.title.split(" ")[6]
        : " ") +
      " " +
      (user.title.split(" ")[7] !== undefined
        ? user.title.split(" ")[7]
        : " ") +
      " " +
      (user.title.split(" ")[8] !== undefined
        ? user.title.split(" ")[8]
        : " ") +
      " " +
      (user.title.split(" ")[9] !== undefined
        ? user.title.split(" ")[9]
        : " ") +
      " " +
      (user.title.split(" ")[10] !== undefined
        ? user.title.split(" ")[10]
        : " ");
  }

  // 16a94e

  return (
    <div className="bg-slate-50 w-screen h-screen flex overflow-y-scroll overflow-x-hidden">
      {/* sibebar  */}
      <div className="flex flex-col font-poppins bg-clip-border rounded-xl bg-white h-full w-full max-w-[20rem]  p-4 shadow-xl shadow-blue-gray-900/5 fixed">
        <div className="flex flex-wrap justify-center mt-10">
          <div className="flex w-full">
            <div className="ml-4 mt-12 pr-12 cursor-pointer">
              <MdArrowBackIos
                color="#9e9e9e"
                size={25}
                onClick={() => navigate("/view-users")}
              />
            </div>
            <div className="flex justify-center items-center w-28">
              {user.image !== null ? (
                <img
                  src={`https://api.adna.cards/public/storage/user_images/${user.profile_photo_path}`}
                  alt=""
                  className="shadow rounded-full w-28 h-28 align-middle border-none"
                />
              ) : (
                <span className="inline-block p-7 rounded-full bg-gray-100">
                  <FaUser color="#727565" size={40} />
                </span>
              )}
            </div>
          </div>
          <div className="mt-6">
            <div className="text-center ">
              <h2 className="text-black text-xl font-bold w-80 ">
                {user.firstname} {user.lastname}
              </h2>
              <p className="text-gray-400 text-xs">{user.title}</p>
              <p className="text-gray-400 text-xs">{user.slug}</p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-14 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <MdOutlinePhoneAndroid color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.phone}
                {user.phone2 !== null ? "/" + user.phone2 : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <MdEmail color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              {user.email !== "" ? (
                <p className="font-semibold">{user.email}</p>
              ) : (
                <p className="font-semibold">{t("description.email")}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <TbWorld color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.company && user.company.website !== null
                  ? user.company.website
                  : t("description.website")}
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full mt-5 rounded-md p-3 bg-white shadow text-gray-500">
            <div className="pr-4">
              <FaLocationDot color="#9e9e9e" size={18} />
            </div>
            <div className="text-sm">
              <p className="font-semibold">
                {user.city}, {user.address}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Vcard */}
      <div className="h-full w-full flex gap-2 flex-col items-center ml-[10rem] mt-[3rem]">
        <div>
          {user.company && user.company.id === 21 ? (
            <PNUDCard
              user={user}
              title1={title1 && title1}
              title2={title2 && title2}
            />
          ) : user.company && user.company.id === 1 ? (
            <ALTECHSCard user={user} />
          ) : (
            <STANDARDCard user={user} />
           )}
        </div>

        <div>
          <Button
            onClick={() =>
              generatePDF(`Card_${user.firstname + user.lastname}`)
            }
            variant="contained"
            size="small"
            style={{
              backgroundColor: "rgb(129 140 248)",
              margin: "6px",
              textTransform: "capitalize",
            }}
          >
            Télécharger
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
