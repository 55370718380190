import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function AddUser() {
  const history = useNavigate();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCompName, setSelectedCompName] = useState();
  const [found, setFound] = useState("");
  const [errors, setErrors] = useState([]);
  const [userInput, setCompany] = useState({
    firstname: "",
    lastname: "",
    title: "",
    city: "",
    address: "",
    phone: "",
    email: "",
    // website: '',
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    pinterest: "",
    company_id: "",
  });

  //find selected country
  const searchSelectedCountry =
    countries &&
    countries.find((obj) => {
      if (obj.name === selectedCountry) {
        return true;
      }
      return false;
    });

  // console.log(searchSelectedCountry);

  const country_code = searchSelectedCountry && searchSelectedCountry.code;
  const user_token = localStorage.getItem("user_token");

  useEffect(() => {
    if (!user_token) {
      history("/");
    }

    axios.get("api/get-companies").then((res) => {
      if (res.data.status === 200) {
        setCompanies(res.data.companies);
      }
    });
    axios.get("api/get-countries").then((res) => {
      if (res.data.status === 200) {
        setCountries(res.data.countries);
      }
    });

    const data = {
      country_code: country_code,
    };

    axios.post("api/get-cities", data).then((res) => {
      if (res.data.status === 200) {
        setCities(res.data.cities);
      }
    });
  }, [country_code]);

  // console.log(selectedCountry);

  const handleInput = (e) => {
    e.persist();
    setCompany({ ...userInput, [e.target.name]: e.target.value });
  };
  const handleComp = (e) => {
    setCompany({ ...userInput, [e.target.name]: e.target.value });

    let comp_name = e.target.selectedOptions[0].getAttribute("isred");
    setSelectedCompName(comp_name);
    console.log(selectedCompName);
  };

  const AddUserSubmit = (e) => {
    e.preventDefault();

    const data = {
      firstname: userInput.firstname,
      lastname: userInput.lastname,
      title: userInput.title,
      country: selectedCountry,
      country_code: searchSelectedCountry.phone_code,
      city: userInput.city,
      address: userInput.address,
      phone: userInput.phone,
      email: userInput.email,
      // website: userInput.website,
      facebook: userInput.facebook,
      linkedin: userInput.linkedin,
      twitter: userInput.twitter,
      instagram: userInput.instagram,
      tiktok: userInput.tiktok,
      youtube: userInput.youtube,
      pinterest: userInput.pinterest,
      company_id: userInput.company_id,
      company_name: selectedCompName,
    };

    console.log(data);

    axios.post("api/add-user", data).then((res) => {
      if (res.data.status === 200) {
        // console.log(res.data)
        alert(res.data.message);
        history("/");
      } else if (res.data.status === 401) {
        alert(res.data.message);
      } else {
        setErrors(res.data.errors);
      }
    });
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <h4 className="relative font-poppins text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 my-8">
        Add a User
      </h4>

      <form class="font-poppins" onSubmit={AddUserSubmit}>
        <div class="w-full max-w-lg">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="firstname"
              >
                First Name*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="firstname"
                type="text"
                placeholder="Jane"
                name="firstname"
                onChange={handleInput}
                value={userInput.firstname}
              />
              <p class="text-red-500 text-xs italic">{errors.firstname}</p>
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="lastname"
              >
                Last Name*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lastname"
                type="text"
                placeholder="Doe"
                name="lastname"
                onChange={handleInput}
                value={userInput.lastname}
              />
              <p class="text-red-500 text-xs italic">{errors.lastname}</p>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="title"
              >
                Title*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="title"
                type="text"
                name="title"
                onChange={handleInput}
                value={userInput.title}
              />
              <p class="text-red-500 text-xs italic">{errors.title}</p>
            </div>
          </div>
          <div class="flex flex-wrap  -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="country"
              >
                Country*
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="country"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  value={selectedCountry}
                  name="country"
                >
                  <option value="">...</option>
                  {countries &&
                    countries.map((count) => (
                      <option value={count.name} key={count.code}>
                        {count.name}
                      </option>
                    ))}
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <p class="text-red-500 text-xs italic">{errors.country}</p>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="city"
              >
                City*
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  name="city"
                  onChange={handleInput}
                  value={userInput.city}
                >
                  <option value="">...</option>
                  {cities &&
                    cities.map((cit) => (
                      <option value={cit.name} key={cit.id}>
                        {cit.name}
                      </option>
                    ))}
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
                <p class="text-red-500 text-xs italic">{errors.city}</p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="address"
              >
                Address*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="address"
                type="text"
                name="address"
                onChange={handleInput}
                value={userInput.address}
              />
              <p class="text-red-500 text-xs italic">{errors.address}</p>
            </div>
          </div>

          <div class="flex flex-row  -mx-3 mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="country_code"
              >
                Phone Code*
              </label>

              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="country_code"
                value={
                  searchSelectedCountry &&
                  "+" + searchSelectedCountry.phone_code
                }
                readOnly
              />

              <p class="text-red-500 text-xs italic">{errors.country_code}</p>
            </div>
            <div class="w-full md:w-1/1 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="phone"
              >
                Phone Number*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="phone"
                type="text"
                name="phone"
                onChange={handleInput}
                value={userInput.phone}
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="email"
              >
                Email*
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="text"
                name="email"
                onChange={handleInput}
                value={userInput.email}
              />
              <p class="text-red-500 text-xs italic">
                {errors.email ? errors.email : found}
              </p>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="company_id"
              >
                Company*
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="company_id"
                  name="company_id"
                  onChange={handleComp}
                  // value={selectedComp}
                  value={userInput.company_id}
                >
                  <option>...</option>
                  {companies &&
                    companies.map((comp) => (
                      <option isred={comp.name} value={comp.id} key={comp.id}>
                        {comp.name}
                      </option>
                    ))}
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <p class="text-red-500 text-xs italic">{errors.company_id}</p>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="facebook"
              >
                Facebook
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="facebook"
                type="text"
                name="facebook"
                onChange={handleInput}
                value={userInput.facebook}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="linkedin"
              >
                Linkedin
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="linkedin"
                type="text"
                name="linkedin"
                onChange={handleInput}
                value={userInput.linkedin}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="twitter"
              >
                Twitter
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="twitter"
                type="text"
                name="twitter"
                onChange={handleInput}
                value={userInput.twitter}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="instagram"
              >
                Instagram
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="instagram"
                type="text"
                name="instagram"
                onChange={handleInput}
                value={userInput.instagram}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="youtube"
              >
                Youtube
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="youtube"
                type="text"
                name="youtube"
                onChange={handleInput}
                value={userInput.youtube}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="tiktok"
              >
                Tiktok
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="tiktok"
                type="text"
                name="tiktok"
                onChange={handleInput}
                value={userInput.tiktok}
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="pinterest"
              >
                Pinterest
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="pinterest"
                type="text"
                name="pinterest"
                onChange={handleInput}
                value={userInput.pinterest}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row  justify-center">
          <div className="mr-5 mb-8">
            <Link
              to={`/view-users`}
              className="text-sm cursor-pointer text-gray-600 mt-4 block py-2 px-10  rounded bg-white border border-gray-200 hover:bg-gray-200 hover:text-gray-600"
            >
              Back
            </Link>
          </div>
          <div className=" mb-8">
            <input
              className="text-sm cursor-pointer text-white mt-4 block border-0 py-2 px-8  rounded bg-indigo-500 border hover:bg-indigo-600"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddUser;
