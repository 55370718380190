import React, { useEffect } from "react";
import userIcon from "../assets/STANDARD/userIcon.png";
import logoAdna from "../assets/STANDARD/logoAdna.png";
import NFC from "../assets/STANDARD/NFC.png";
import QRCodeStyling from "qr-code-styling";
import { useParams } from "react-router-dom";
import "../styles/STANDARDCard.css";

const STANDARDCard = ({ user }) => {
  const dataR = localStorage.getItem("slug");
  const dataV = localStorage.getItem("vcard");
  const { id } = useParams();

  useEffect(() => {
    qrCodeR.append(document.getElementById("qrcodeR"));
    qrCodeV.append(document.getElementById("qrcodeV"));
  }, [id]);

  const qrCodeR = new QRCodeStyling({
    width: 140,
    height: 140,
    // margin: 2,
    type: "svg",
    data: `https://vcard.adna.cards/vcard/card/qr/${dataR}`,
    dotsOptions: {
      color: "black",
      type: "dots",
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      type: "dots",
    },
    cornersDotOptions: {
      type: "dot",
    },
  });

  const qrCodeV = new QRCodeStyling(
    dataV && dataV.length < 360
      ? {
          width: 180,
          height: 180,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 500
      ? {
          width: 210,
          height: 210,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 661
      ? {
          width: 230,
          height: 230,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : dataV && dataV.length < 780
      ? {
          width: 240,
          height: 240,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
      : {
          width: 260,
          height: 260,
          type: "svg",
          data: dataV,
          dotsOptions: {
            color: "black",
            type: "dots",
          },
          backgroundOptions: {
            color: "#ffffff",
          },
          cornersSquareOptions: {
            type: "dots",
          },
          cornersDotOptions: {
            type: "dot",
          },
        }
  );

  return (
    <>
      <div id="cardR" className="backImgST">
        <div className="userIconST">
          <img src={userIcon} alt="" style={{ width: "57px" }} />
        </div>
        <div className="flex flex-col nameSectionST ">
          {user.firstname &&
          user.lastname &&
          user.lastname.length + user.firstname.length < 30 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "27px", lineHeight: "36px" }}
            >
              {/* DIKOUM Eveline */}
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 38 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "24px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 40 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "21px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : user.firstname &&
            user.lastname &&
            user.lastname.length + user.firstname.length < 45 ? (
            <p
              className="text-black font-poppins font-extrabold"
              style={{ fontSize: "19px", lineHeight: "36px" }}
            >
              {user.firstname} {user.lastname}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-extrabold"
              style={{
                fontSize: "18px",
                lineHeight: "36px",
                maxWidth: "70%",
              }}
            >
              {user.firstname} {user.lastname}
            </p>
          )}

          {user.title && user.title.length < 58 ? (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "20px",
                lineHeight: "18px",
              }}
            >
              {user.title}
              {/* Consultante En Réseau Informatique */}
            </p>
          ) : (
            <p
              className="text-black font-poppins font-regular"
              style={{
                fontSize: "17px",
                lineHeight: "18px",
                maxWidth: "70%",
              }}
            >
              {user.title}
            </p>
          )}
        </div>

        <div
          className={
            user.title && user.title.length < 70 ? "logoAdnaST" : "logoAdnaST2"
          }
        >
          <img src={logoAdna} alt="" style={{ width: "116px" }} />
        </div>
        <div className="flex flex-row  items-center ">
          <div
            className={
              user.title && user.title.length < 70
                ? "smartCardST"
                : "smartCardST2"
            }
          >
            <img src={NFC} alt="" style={{ width: "58px" }} />
          </div>
          <div
            className={
              user.title && user.title.length < 70
                ? "smartCardST"
                : "smartCardST2"
            }
          >
            <p
              className="text-black font-bold font-harabara"
              style={{
                fontSize: "20px",
                lineHeight: "15px",
                letterSpacing: "1px",
                marginLeft: "8px",
              }}
            >
              Smart
              <br />
              <span style={{ color: "#5C7BDF" }}>Business</span>
              <br />
              Card
            </p>
          </div>
        </div>
        <div
          id="qrcodeR"
          className={
            user.title && user.title.length < 70 ? "flex justify-center qrcodeST" : "flex justify-center qrcodeST2"
          }
          style={{
            width: 140,
            height: 140,
            backgroundColor: "rgb(255 255 255)",
          }}
        ></div>
      </div>
      <br />
      <div
        id="cardV"
        className="flex justify-center items-center backImgVersoST"
      >
        <div
          id="qrcodeV"
          style={
            dataV && dataV.length < 360
              ? {
                  width: 180,
                  height: 180,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(1)",
                }
              : dataV && dataV.length < 500
              ? {
                  width: 210,
                  height: 210,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.9)",
                }
              : dataV && dataV.length < 661
              ? {
                  width: 230,
                  height: 230,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.9)",
                }
              : dataV && dataV.length < 780
              ? {
                  width: 240,
                  height: 240,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.8)",
                }
              : {
                  width: 260,
                  height: 260,
                  backgroundColor: "rgb(255 255 255)",
                  transform: "scale(.7)",
                }
          }
        ></div>
      </div>
    </>
  );
};

export default STANDARDCard;
